*,
*::after,
*::before {
  padding: 0px;
  margin: 0px;
  box-sizing: inherit;
}
body {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
ul,
li {
  list-style: none;
  margin: 0;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.signWapper {
  border: 1px solid #000;
  border-radius: 32px;
  font-size: 0.65rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  padding-bottom: 4rem;
  gap: 1.5rem;
  width: 85%;
  margin: 3rem auto;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 2rem 5px;
  }
  h1 {
    font-size: 4em;
    font-weight: 600;
  }
  .option {
    text-align: center;
    font-size: 1.4em;
    font-weight: 500;
    span a {
      color: #00c8d5;
      cursor: pointer;
      text-decoration: none;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: darken(#00c8d5, 5%);
      }
    }
  }
}
