@import '../../../sass/mixin.scss';
@import '../../../sass/variables.scss';

.teachersCards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  align-items: space-between;
  column-gap: 22px;
  row-gap: 32px;
  padding: 1rem 5rem;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.06))
    drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1));
  .teacher {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #f1f1f1;
    border-radius: 16px;
    padding: 7.5px;
    padding-bottom: 14px;
    max-width: 250px;
    img {
      grid-column: 1/-1;
      display: block;
      width: 100%;
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.06))
        drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1));
    }
    .name {
      grid-column: 1/-1;
      font-weight: 700;
      font-size: 16px;
      color: #434d57;
      margin-top: 10px;
    }
    .title {
      grid-column: 1/-1;
      font-weight: 600;
      font-size: 13px;
      color: #434d57;
    }
    .school {
      font-weight: 500;
      font-size: 12px;
      color: #434d57;
      display: inline-block;
    }
    .action {
      background-color: $blueColor;
      color: #fff;
      border-radius: 6px;
      font-weight: 500;
      font-size: 12px;
      padding: 4px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: none;
    }
  }
  .teacher-info {
    font-size: 16px;
  }
}
