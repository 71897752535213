
.pricing-plans {
  gap: 32px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  width: 50%;
}

.pricing-card {
  --col: #e4e4e7;
  position: relative;
  min-width: 360px;
  padding: 32px;
  padding-bottom: 96px;
  border-radius: 4px;
  border: 1px solid #0064ff;
  background-color: #0064ff;
  box-shadow: 0 0 32px transparent;
  text-align: center;
}

.pricing-card.basic {
  --col: #0064ff;
}

.pricing-card.standard {
  --col: #059669;
}

.pricing-card.premium {
  --col: #c026d3;
}

.pricing-card:hover {
  border-color: var(--col);
  background-color: #0064ff;
  box-shadow: 0 0 32px #0064ff;
  transform: translateY(-16px) scale(1.02);
  transition: all 0.5s ease;
}

.pricing-card > *:not(:last-child) {
  margin-bottom: 32px;
}

.pricing-card .heading h4 {
  padding-bottom: 12px;
  color: #fff;
  font-size: 24px;
  font-weight: normal;
}

.pricing-card .heading p {
  color: #fff;
  font-size: 14px;
  font-weight: lighter;
}

.pricing-card .price {
  position: relative;
  color: #fff;
  font-size: 60px;
  font-weight: bold;
}

.pricing-card .price sub {
  position: absolute;
  bottom: 14px;
  color: #fff;
  font-size: 14px;
  font-weight: lighter;
}

.pricing-card .features li {
  padding-bottom: 16px;
  color: #fff;
  font-size: 16px;
  font-weight: lighter;
  text-align: left;
}

.pricing-card .features li i,
.pricing-card .features li strong {
  color: #fff;
  font-size: 16px;
  text-align: left;
}

.pricing-card .features li strong {
  padding-left: 24px;
}

.pricing-card .cta-btn {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid var(--col);
  background-color: var(--col);
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.pricing-card .cta-btn:active {
  background-color: transparent;
  color: #fff;
  transition: all 0.3s ease;
}

.pricing-plans .pricing-card .btn.btn-primary.d-block{
  width: 100%;
  background-color: rgb(255, 255, 255);
  color: #0d6efd;
  border-radius: 20px;
}